<template>
  <component
    :is="PageWrapper"
    v-bind="{...props, page}"
    class="outline-none"
    :content="content"
  >
    <slot />
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultWrapper from '@/themes/default/DefaultWrapper'

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
    props: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('settings', ['layout']),
    PageWrapper() {
      if (this.layout === 'default') {
        return DefaultWrapper
      }
      return () => ({
        component: import(`@/themes/${this.layout}/pages/${
          this.page
        }.vue`).catch((e) => {
          if (process.env.DEV)
            console.warn(`
              Theme Override for /pages/${this.page} doesn’t exist.
              Full path: @/themes/${this.layout}/pages/${this.page}.vue
            `)
          return import(`@/themes/${this.layout}/DefaultWrapper.vue`).catch(
            () => {
              return DefaultWrapper
            }
          )
        }),
        error: DefaultWrapper,
        loading: import(`@/themes/${this.layout}/LoadingWrapper.vue`).catch(
          (e) => DefaultWrapper
        ),
      })
    },
  },
}
</script>

<style>
.main-content ol,
.main-content ul {
  padding: revert;
  list-style: revert;
}

ol[type='upper-alpha'] {
  list-style-type: upper-alpha;
}
ol[type='lower-alpha'] {
  list-style-type: lower-alpha;
}
ol[type='upper-roman'] {
  list-style-type: upper-roman;
}
ol[type='lower-roman'] {
  list-style-type: lower-roman;
}
ol[type='number'] {
  list-style-type: decimal;
}
</style>
<style lang="scss">
@for $i from 1 through 25 /* max-indent */ {
  $indent-margin-base: 1rem;
  *[data-indent='#{$i}'] {
    margin-left: $indent-margin-base * $i !important;
  }
}
</style>
