const supportedMetaFields = {
  keywords: 'keywords',
  description: 'description',
  'og:description': 'description',
  'og:title': 'title',
  'og:image': 'thumbnail',
  'og:url': 'url',
}

export function metaTags(metaFields = {}, baseValues = {}) {
  const merged = {
    ...baseValues,
    ...metaFields,
  }
  return Object.entries(supportedMetaFields).reduce((acc, curr) => {
    const [tagType, valueSource] = curr
    const content = merged[valueSource]
    if (content)
      acc.push({
        hid: tagType,
        property: tagType,
        content,
      })
    return acc
  }, [])
}

export function metaTitle(
  metaFields = {},
  catalogWideMetaTitle,
  titleFallback
) {
  const titleValue = metaFields.title || titleFallback
  return {
    ...(titleValue && {
      title: `${titleValue} | ${catalogWideMetaTitle}`,
    }),
  }
}
